// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nephron_running_time_box__3AmYm {\n    display: flex;\n    flex-direction: column;\n    grid-gap: 5px;\n    gap: 5px;\n\n}\n\n.nephron_running_time__3AY-g {\n    display: flex;\n    grid-gap: 15px;\n    gap: 15px;\n}\n\n.nephron_nep_icon_text__cGW93 {\n    word-break: keep-all;\n}", "",{"version":3,"sources":["webpack://src/pages/nephron/nephron.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAQ;IAAR,QAAQ;;AAEZ;;AAEA;IACI,aAAa;IACb,cAAS;IAAT,SAAS;AACb;;AAEA;IACI,oBAAoB;AACxB","sourcesContent":[".running_time_box {\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n\n}\n\n.running_time {\n    display: flex;\n    gap: 15px;\n}\n\n.nep_icon_text {\n    word-break: keep-all;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"running_time_box": "nephron_running_time_box__3AmYm",
	"running_time": "nephron_running_time__3AY-g",
	"nep_icon_text": "nephron_nep_icon_text__cGW93"
};
export default ___CSS_LOADER_EXPORT___;
