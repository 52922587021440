import React, { useEffect } from 'react';
import {useHistory} from 'react-router-dom';
import style from './CertificationDone.module.css'
import { ChangeDoneIcon } from '../../../assets/icons/SVG';

const CertificationDone = () => {
    const history = useHistory()
    return (
      <div className={style["page-start"]}>
        <div className={style.root}>
          <ChangeDoneIcon></ChangeDoneIcon>
          <div className={`${style["title"]} ${style["centerItem"]}`}>
            수퍼빈 회원 인증 완료
          </div>
          <div className={`${style["sub-title"]} ${style["centerItem"]}`}>
            이제 탄소중립포인트를 받기 위한 첫번째 단계가 끝났습니다. 홈화면으로
            돌아가서 탄소중립포인트 배너를 다시 눌러주세요.
          </div>
          <div className={style["sub-description-box"]}>
            <div className={style["sub-description"]}>
              <p className={style["title"]}>※ 탄소중립포인트 회원가입 문의처</p>
              <p className={style["body"]}> - 문의전화 : 1660-2030</p>
              <p className={style["body"]}> - 이메일 : netzero@keiti.re.kr</p>
            </div>
          </div>
          <div className={style["bottom-container-box"]}>
            <button
              onClick={() => {
                history.replace("/");
              }}
            >
              메인으로 돌아가기
            </button>
          </div>
        </div>
      </div>
    );
};

export default CertificationDone;