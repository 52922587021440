
import axios from 'axios';
import { API_ENTITY_APP_ROOT, VERSION, BUILD_NO } from '../config';
import AuthService from './AuthService';
import HttpHelper from '../helpers/HttpHelper/HttpHelper';

axios.defaults.headers.common['X-Client-Version'] = VERSION;
axios.defaults.headers.common['X-Client-Build'] = BUILD_NO;
axios.defaults.headers.common['Cache-Control'] = "no-cache";
axios.defaults.headers.common['Access-Control-Allow-Header'] = "Accept, Content-Type";
axios.defaults.headers.common['Access-Control-Allow-Methods'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";

const Service = {
  get: async (endpoint, data) => {
    try {
      const result = await axios.get(API_ENTITY_APP_ROOT + endpoint, { params: data });
      return result.data;
    } catch (e) {
      const error_code = e.response.data.error_code;
      const message = e.response.data.message;
      if (e.response.status === 401) {
        if (error_code === -100) {
          //토큰 아예 없음
          alert(message);
          try{
            window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
          }catch(err){
            console.log(err)
          }
        }
        else if (error_code === -101){
          //토큰 expire 됨
          const testResult = await HttpHelper.getTokenByrefreshToken()
          if(testResult){
            await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
            axios.defaults.headers.common['x-token'] = testResult.access_token;
            const reloadResult = await axios.get(API_ENTITY_APP_ROOT + endpoint, { params: data });
            return reloadResult.data;
          }else{
            alert("로그아웃 되었습니다. 다시 로그인 해주세요");
            try{
              window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
            }catch(err){
              console.log(err)
            }
          }
        }
        else{
          throw e;
        }
      }
      throw e;
    }
  },
  post: async (endpoint, data) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let body;
    switch (data.constructor) {
      case Object:
        body = JSON.stringify(data);
        break;
      case FormData:
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        break;
    }
    try{
      const result = await axios.post(API_ENTITY_APP_ROOT + endpoint, body, config);
      return result.data;
    }catch(e){
      const error_code = e.response.data.error_code;
      const message = e.response.data.message;
      if (e.response.status === 401) {
        if (error_code === -100) {
          alert(message);
          try{
            window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
          }catch(err){
            console.log(err)
          }
        }
        else if (error_code === -101){
          const testResult = await HttpHelper.getTokenByrefreshToken()
          if(testResult){
            await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
            axios.defaults.headers.common['x-token'] = testResult.access_token;
            const reloadResult = await axios.post(API_ENTITY_APP_ROOT + endpoint, body, config);
            return reloadResult.data;
          }else{
            alert("로그아웃 되었습니다. 다시 로그인 해주세요");
            try{
              window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
            }catch(err){
              console.log(err)
            }
          }
        }
        else{
          throw e;
        }
      }
      throw e;
    }
  },
  put: async (endpoint, data) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let body;
    switch (data.constructor) {
      case Object:
        body = JSON.stringify(data);
        break;
      case FormData:
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        break;
    }
    try{
      const result = await axios.put(API_ENTITY_APP_ROOT + endpoint, body, config);
      return result.data;
    }catch(e){
      const error_code = e.response.data.error_code;
      const message = e.response.data.message;
      if (e.response.status === 401) {
        if (error_code === -100) {
          alert(message);
          try{
            window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
          }catch(err){
            console.log(err)
          }
        }
        else if (error_code === -101){
          const testResult = await HttpHelper.getTokenByrefreshToken()
          if(testResult){
            await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
            axios.defaults.headers.common['x-token'] = testResult.access_token;
            const reloadResult = await axios.put(API_ENTITY_APP_ROOT + endpoint, body, config);
            return reloadResult.data;
          }else{
            alert("로그아웃 되었습니다. 다시 로그인 해주세요");
            try{
              window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
            }catch(err){
              console.log(err)
            }
          }
        }
        else{
          throw e;
        }
      }
      throw e;
    }

  },
  delete: async (endpoint, data) => {
    const config = { headers: { 'Content-Type': 'application/json' } };
    let body;
    switch (data.constructor) {
      case Object:
        body = JSON.stringify(data);
        break;
      case FormData:
        body = data;
        break;
      default:
        body = JSON.stringify(data);
        break;
    }
    try{
      const result = await axios.delete(API_ENTITY_APP_ROOT + endpoint, { data: body }, config);
      return result.data;
    }catch(e){
      const error_code = e.response.data.error_code;
      const message = e.response.data.message;
      if (e.response.status === 401) {
        if (error_code === -100) {
          alert(message);
          try{
            window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
          }catch(err){
            console.log(err)
          }
        }
        else if (error_code === -101){
          const testResult = await HttpHelper.getTokenByrefreshToken()
          if(testResult){
            await AuthService.setAccessTokenByRefreshToken(testResult.access_token)
            axios.defaults.headers.common['x-token'] = testResult.access_token;
            const reloadResult = await axios.delete(API_ENTITY_APP_ROOT + endpoint, { data: body }, config);
            return reloadResult.data;
          }else{
            alert("로그아웃 되었습니다. 다시 로그인 해주세요");
            try{
              window.nativeApi.execute(window.NativeApiMethod.CLOSE_WEB_VIEW, {}, '');
            }catch(err){
              console.log(err)
            }
          }
        }
        else{
          throw e;
        }
      }
      throw e;
    }
  },
};

export default Service;
