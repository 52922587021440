// export const API_ROOT = "/api/";
//test
// export const CMS_API_ROOT = "https://api.superbin.link/test/homepage/v1";
// export const DEVICE_ROOT = "http://deviceapi.superbin.co.kr"
// export const Auth_ROOT = "http://api.superbin.link/test/app-auth/v1"
// export const Auth_FRONT = "10.100.0.189:8010/"
//stag
// export const CMS_API_ROOT = window.location.protocol+"//api.superbin.link/stag/homepage/v1";
// export const DEVICE_ROOT = window.location.protocol+"//deviceapi.superbin.co.kr"
// export const Auth_ROOT = window.location.protocol+"//api.superbin.link/stag/app-auth/v1/"
// export const Auth_FRONT = window.location.protocol+"//auth.superbin.link/"
// export const Share_Domain = "superbin.link"

//prod
export const CMS_API_ROOT = window.location.protocol+"//"+process.env.REACT_APP_API_ENTITY_CMS_URL;
// export const DEVICE_ROOT = window.location.protocol+"//"+process.env.REACT_APP_API_ENTITY_DEVICE_URL;
export const Auth_ROOT = window.location.protocol+"//"+process.env.REACT_APP_API_ENTITY_APP_AUTH_URL;
export const Auth_FRONT = window.location.protocol+"//"+process.env.REACT_APP_FRONT_USER_AUTH_URL;
export const API_ENTITY_APP_ROOT = window.location.protocol+"//"+process.env.REACT_APP_API_ENTITY_APP_URL;

export const API_BFF_APP_URL = window.location.protocol+"//"+process.env.REACT_APP_API_BFF_APP_URL;


export const Share_Domain = process.env.REACT_APP_COOKIE_SHARE_URL;
// export const Auth_ROOT = "/auth/"
export const RELEASE_NOTE_ID = 5;

export const VERSION = "0.1.1";
export const BUILD_NO = 101;
